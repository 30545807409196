@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

body {
    font-family: 'Inter', sans-serif;
    color: #4b5563;
}

.container {
    max-width: 1260px;
    margin: 0 auto;
}

.section-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
}
